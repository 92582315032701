import * as React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@mui/material'
import Layout from '../layouts'
import Seo from '../components/seo'
import PageWrap from '../components/pageWrap'
import Topbar from '../components/topbar'
import Footer from '../components/footer'
import Hero from '../components/hero'
import TopInfo from '../components/topinfo'
import Section from '../components/section'

const IndexPage = ({ pageContext, data }) => (
  <Layout>
    <Topbar lang={pageContext.langKey} />
    <PageWrap>

      {data && data.allFile.nodes.map((node) => {
        return (
          <Box key={node.name}>
            {node.childMarkdownRemark.frontmatter.hero &&
              <Hero />
            }

            {node.name === 'index' &&
              <TopInfo html={node.childMarkdownRemark.html} />
            }

            {node.name !== 'index' &&
              <Section
                name={node.name}
                title={node.childMarkdownRemark.frontmatter.title}
                html={node.childMarkdownRemark.html}
              />
            }
          </Box>
        )
      })}

    </PageWrap>
    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    allFile(
      filter: {relativeDirectory: {eq: "de"}}
      sort: {fields: childMarkdownRemark___frontmatter___weight, order: ASC}
      ) {
      nodes {
        name
        childMarkdownRemark {
          frontmatter {
            title
            hero
          }
          html
        }
      }
    }
  }
`

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage